export default {
  bids: {
    aau: 'https://vblimages.blob.core.windows.net/images/Badges/badge_aau_bid.png',
    aaubob: 'https://vblimages.blob.core.windows.net/images/f51de7ab-e99b-4f05-81ff-45830ca7804f.png',
    aauwcjo: 'https://vblimages.blob.core.windows.net/images/d9a6c50f-c59a-4b3e-8c6a-dc283c83d82a.png',
    bvne: 'https://vblimages.blob.core.windows.net/images/Badges/badge_bvne_bid.png',
    p1440: 'https://vblimages.blob.core.windows.net/images/Badges/badge_p1440_bid.png',
    bomb: 'https://vblimages.blob.core.windows.net/images/97493bee-776b-477e-b1c9-ea7a4352d864.png',
    avpa: 'https://vblimages.blob.core.windows.net/images/eeaad91b-fef3-47a8-a163-1354a63a39a5.png',
    usav: 'https://vblimages.blob.core.windows.net/images/b1377311-fff3-4786-ad57-92e5adbc4798.png'
  },
  AAA: 'https://vblimages.blob.core.windows.net/images/Badges/badge_blue_AAA.png'
}
